<template>
    <app-page no-container>
        <template #page-header>
            <page-header name="Projects" :loading="loading" v-model="search">
                <b-btn to="/project/create" variant="primary">Create project <i class="fas fa-plus"></i></b-btn>
            </page-header>
        </template>
        <b-overlay :show="loading" rounded="sm" class="px-4 pb-3 mx-auto" style="max-width: 1600px;">
            <template v-if="!search">
                <h5 class="text-left mt-3 mb-0">Actives Projects ({{runningProjects.length}})</h5>
                <b-row>
                    <b-col class="mt-3" lg="3" md="4" sm="6" xs="12" v-for="project in runningProjects" :key="project.name" v-b-tooltip :title="project.description ? project.description.substring(0, 150) : ``">
                        <project-card :project="project"/>
                    </b-col>
                </b-row>
<!--                <hr>-->
                <h5 class="text-left mt-3 mb-0">Inactives Projects ({{stoppedProjects.length}})</h5>
                <b-row>
                    <b-col class="mt-3" lg="3" md="4" sm="6" xs="12" v-for="project in stoppedProjects" :key="project.name">
                        <project-card :project="project"/>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <h5 class="text-left mt-3 mb-0">Search results for: {{search}} ({{filteredProjects.length}})</h5>
                <b-row>
                    <b-col class="mt-3" lg="3" md="4" sm="6" xs="12" v-for="project in filteredProjects" :key="project.name">
                        <project-card :project="project"/>
                    </b-col>
                </b-row>
            </template>

        </b-overlay>
    </app-page>
</template>

<script>

import Network from "../../vue-components/helpers/Network.js";
import ProjectCard from "../components/project-card.vue";

export default {
    name: `projects`,
    components: {ProjectCard},
    data() {
        return {
            loading: true,
            projects: [],
            search: ``
        }
    },
    computed: {
        runningProjects() {
            return this.filteredProjects.filter(p => p.pods_count > 0);
        },
        stoppedProjects() {
            return this.filteredProjects.filter(p => p.pods_count <= 0);
        },
        filteredProjects() {
            if (!this.search) {
                return this.projects;
            }

            return this.projects.filter(p => p.name.toLowerCase().includes(this.search.toLowerCase()))
        }
    },
    activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );
        this.init();
    },
    deactivated() {
        this.unwatch();
    },
    methods: {
        init() {
            this.getProjects();
        },
        getProjects() {
            this.loading = true;
            Network.get(`/api/projects/${this.$store.state.cluster}`)
                .then(resp => {
                    this.projects = resp.data;
                    this.loading = false;
                });
        },
        beautifyName(projectName) {
            return projectName.replaceAll(`-`, ` `).replace(`api`, `API`).replace(`smtp`, `SMTP`).replace(`https`, `HTTPS`).replace(`dns`, `DNS`);
        },
        tagVariant(tag) {
            if (!tag) {
                return `light`;
            }
            switch (tag.toLowerCase()) {
                case `php`:
                    return `light-warning`;
                default:
                    return `light`;
            }
        },
        getIcon(project) {
            if (project.name.indexOf(`api`) >= 0) {
                return `https://eyeinlivestorage.blob.core.windows.net/public/hosting/projects/api.png`;
            }
            return `https://eyeinlivestorage.blob.core.windows.net/public/hosting/projects/${project.name}.png`;
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-project {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .name-and-icon {
        display: flex;
        align-items: center;
        text-align: left;
    }

    .name {
        font-weight: 500;
        text-transform: capitalize;
    }

    .icon {
        height: 60px;
        width: 60px;
        object-fit: contain;
        margin-left: -5px;
        margin-right: 10px;
    }

    .open-logs {
        position: absolute;
        display: block;
        padding: 3px 10px;
        top: 2px;
        right: 2px;
        border-radius: 5px;
        background-color: white;

        &:hover {
            background-color: #ddd;
        }
    }

    .tags {
        .badge + .badge {
            margin-left: 3px;
        }
    }
}
</style>
