<template>
    <b-btn class="project-card" :to="'/project/'+project.name" block v-b-tooltip :title="project.description ? project.description.substring(0, 150) : ``">
        <div class="name-and-icon">
            <b-img class="icon" :src="getIcon(project)" @load="imgLoaded" v-show="!missingImage"/>
            <b-img class="icon" v-show="missingImage" src="https://eyeinlivestorage.blob.core.windows.net/public/hosting/projects/api.png"/>
            <div class="content">
                <div class="name">
                    {{ beautifyName(project.name) }}
                </div>
                <div class="tags">
                    <b-badge class="pods-status" v-if="project.pods_count > 0" variant="light-success" v-b-tooltip title="Number of Pods Replicated">
                        <i class="fas fa-circle-check"/>
                        {{ project.pods_count }}
                        <span v-if="project.pods_count > 1">Pods</span>
                        <span v-else>Pod</span>
                    </b-badge>
                    <b-badge class="pods-status" v-else variant="danger" v-b-tooltip title="No Pods Running">
                        Stopped
                    </b-badge>
                    <b-badge v-if="firewallDisabled" variant="danger" v-b-tooltip title="Firewall disabled">
                        Firewall disabled
                    </b-badge>
                    <b-badge v-if="firewallForced" variant="success" v-b-tooltip title="Firewall forced">
                        Firewall forced
                    </b-badge>
                    <b-badge v-for="tag of project.tags" :key="project.name + tag.value" :variant="tagVariant(tag.value)">{{ tag.value }}</b-badge>
                </div>
            </div>
        </div>
        <router-link class="text-dark open-logs" :to="'/pods/'+project.name" v-b-tooltip title="Open Logs"><i class="fas fa-rectangle-terminal fa-sm"></i></router-link>
    </b-btn>
</template>

<script>
export default {
    name: `project-card`,
    props: {
        project: {type: Object, required: true}
    },
    data() {
        return {
            missingImage: true
        }
    },
    computed: {
        firewallDisabled() {
            if (!this.project) {
                return false;
            }

            const envVar = this.project.environmentVariables.find(ev => ev.name === `PROJECT_USE_FIREWALL`);

            if (!envVar) {
                return false;
            }

            return envVar.value === `no`;
        },
        firewallForced() {
            if (!this.project) {
                return false;
            }

            const envVar = this.project.environmentVariables.find(ev => ev.name === `PROJECT_USE_FIREWALL`);

            if (!envVar) {
                return false;
            }

            return envVar.value === `yes`;
        }
    },
    methods: {
        beautifyName(projectName) {
            return projectName.replaceAll(`-`, ` `).replace(`api`, `API`).replace(`smtp`, `SMTP`).replace(`https`, `HTTPS`).replace(`dns`, `DNS`);
        },
        tagVariant(tag) {
            if (!tag) {
                return `light`;
            }
            switch (tag.toLowerCase()) {
                case `php`:
                    return `light-warning`;
                default:
                    return `light`;
            }
        },
        getIcon(project) {
            if (project.name.indexOf(`api`) >= 0) {
                return `https://eyeinlivestorage.blob.core.windows.net/public/hosting/projects/api.png`;
            }
            return `https://eyeinlivestorage.blob.core.windows.net/public/hosting/projects/${project.name}.png`;
        },
        imgLoaded() {
            this.missingImage = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.project-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .name-and-icon {
        display: flex;
        align-items: center;
        text-align: left;
    }

    .name {
        font-weight: 500;
        text-transform: capitalize;
        color: #333;
        margin-bottom: 2px;
    }

    .icon {
        height: 60px;
        width: 60px;
        padding: 3px;
        object-fit: contain;
        margin-left: -5px;
        margin-right: 10px;
    }

    .open-logs {
        position: absolute;
        display: block;
        padding: 3px 10px;
        top: 2px;
        right: 2px;
        border-radius: 5px;
        background-color: white;

        &:hover {
            background-color: #ddd;
        }
    }

    .tags {
        .badge + .badge {
            margin-left: 3px;
        }
    }
}
</style>
